import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, IconButton, Menu, MenuItem, useMediaQuery, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import styles from "./ResponsiveAppBar.module.scss";

const NavigationBar = () => {
    // Explicitly type the state to accept both null and HTMLElement
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar position="static" sx={{ px: 1 }} className={styles.centeredAppBar}>
            <Toolbar sx={{ justifyContent: 'space-between', px: '10px' }} className={styles.appBarContainer}>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="logo"
                    component={RouterLink}
                    to="/"
                    sx={{ mr: 2 }}
                >
                    <img src="/images/logo/cf_bites.png" alt="Logo" style={{ maxHeight: '50px' }} />
                </IconButton>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Caribbean Fusion Bites
                </Typography>
                {isMobile ? (
                    <>
                        <IconButton
                            edge="end"
                            color="inherit"
                            aria-label="menu"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleClose} component={RouterLink} to="/">Home</MenuItem>
                            <MenuItem onClick={handleClose} component={RouterLink} to="/menu">Menu</MenuItem>
                            <MenuItem onClick={handleClose} component={RouterLink} to="/about-us">About Us</MenuItem>
                            <MenuItem onClick={handleClose} component={RouterLink} to="/contact-us">Contact Us</MenuItem>
                        </Menu>
                    </>
                ) : (
                    <>
                        <Button color="inherit" component={RouterLink} to="/">Home</Button>
                        <Button color="inherit" component={RouterLink} to="/menu">Menu</Button>
                        <Button color="inherit" component={RouterLink} to="/about-us">About Us</Button>
                        <Button color="inherit" component={RouterLink} to="/contact-us">Contact Us</Button>
                    </>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default NavigationBar;
