import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import Menu from './pages/Menu/Menu';
import AboutUs from './pages/AboutUs/AboutUs';
import ContactUs from './pages/ContactUs/ContactUs';
import ResponsiveAppBar from './components/ResponsiveAppBar/ResponsiveAppBar';
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import theme from "./theme";
import {ThemeProvider} from "@mui/material";
const App: React.FC = () => {
    return (
        <ThemeProvider theme={theme}>
        <Router>
            <ResponsiveAppBar />
            <Routes>
                <Route path="*" element={<NotFoundPage />} />
                <Route path="/" element={<Home />} />
                <Route path="/menu" element={<Menu />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/contact-us" element={<ContactUs />} />
            </Routes>
        </Router>
            </ThemeProvider>
    );
};

export default App;
