import React from 'react';
import {Typography, Link as MuiLink, IconButton, Grid} from '@mui/material';
import { FaFacebookF, FaInstagram, FaTiktok } from 'react-icons/fa';
import { MdEmail, MdPhone } from 'react-icons/md';
import styles from '../../assets/styles/shared.module.scss';

const ContactUs = () => {
    // This function could be used to handle more complex interactions, like logging analytics events
    const handleSocialClick = (url: string | URL | undefined) => () => window.open(url, '_blank');

    return (
        <main className={`${styles.centeredLayout} ${styles.contactPage}`}>
            <section className={styles.container}>
                <div className={styles.logoContainer}>
                    <img src="/images/logo/cf_bites.png" alt="Caribbean Fusion Bites Logo" className={styles.logo} />
                </div>

                <Typography variant="h2" component="h1" gutterBottom>Contact Us</Typography>
                <Typography variant="body1" paragraph>Our food truck is almost ready to serve you delicious meals!</Typography>

                <div className={styles.spacing}></div>



                <Typography variant="h6" component="h2" gutterBottom>Connect With Us</Typography>

                <Grid container spacing={2}   direction="row"
                      alignItems="center" justifyContent="center" columns={12}>
                    <Grid item xs={12} md={6}  style={{ padding: 'px' }}>
                        <section className={styles.contactInfo}>
                            <MuiLink href="mailto:support@cfbites.com" target="_blank" className={styles.contactLink}>
                                <MdEmail className={styles.icon} /> support@cfbites.com
                            </MuiLink>
                            <MuiLink href="tel:+14074941885" className={styles.contactLink}>
                                <MdPhone className={styles.icon} /> +1 (407) 494-1885
                            </MuiLink>
                        </section>
                    </Grid>
                    <Grid item xs={12} md={6} >
                        <section className={styles.socialLinks}>
                            <IconButton onClick={handleSocialClick('https://facebook.com/caribbeanfusionbites')} aria-label="Facebook">
                                <FaFacebookF />
                            </IconButton>
                            <IconButton onClick={handleSocialClick('https://instagram.com/cf.bites')} aria-label="Instagram">
                                <FaInstagram />
                            </IconButton>
                            <IconButton onClick={handleSocialClick('https://tiktok.com/@cf.bites')} aria-label="TikTok">
                                <FaTiktok />
                            </IconButton>
                        </section>

                    </Grid>
                </Grid>
            </section>
        </main>
    );
};

export default ContactUs;
