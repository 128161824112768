import React from 'react';
import styles from '../../assets/styles/shared.module.scss';
import { Typography, Link as MuiLink, Grid } from "@mui/material";
import { FaFacebookF, FaInstagram, FaTiktok } from 'react-icons/fa';


const AboutUs: React.FC = () => {
    return (
        <div className={styles.centeredLayout}>
            <div className={styles.container2}>
                <Typography variant="h1" component="h1">Who We are: </Typography>
                <Grid container spacing={2}   direction="row"
                      alignItems="center" justifyContent="center" columns={12}>
                    <Grid item xs={12} md={2}  style={{ padding: 'px' }}>
                        <div className={styles.logoContainer}>
                            <img src="/images/logo/cf_bites.png" alt="Caribbean Fusion Bites Logo" className={styles.logoSmall} />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={10} >
                        <Typography variant="body2">
                            Caribbean Fusion Bites redefines street food, offering a fresh and exciting twist to your dining experience straight from our food trailer. We blend the vibrant flavors of the Caribbean with global culinary traditions, serving up an innovative menu of small plates and bites perfect for sharing or a quick individual indulgence. Dive into our spicy jerk chicken sliders, sweet and tangy mango ceviche, and more—all crafted with passion using the freshest ingredients for bold, mouthwatering flavors. Ideal for a quick lunch, a delightful dinner, or a flavorful adventure on the go, our menu caters to the whole family. Embrace the spirit of the Caribbean and the diversity of our global community with Caribbean Fusion Bites. Follow us on social media to find out where our journey takes us next and to explore a world of flavor!
                        </Typography>

                    </Grid>
                </Grid>
                <Typography variant="body1">Follow your taste buds</Typography>

                <div className={styles.socialLinks}>
                    <MuiLink href="https://facebook.com/caribbeanfusionbites" target="_blank"><FaFacebookF /></MuiLink>
                    <MuiLink href="https://instagram.com/cf.bites" target="_blank"><FaInstagram /></MuiLink>
                    <MuiLink href="https://tiktok.com/@cf.bites" target="_blank"><FaTiktok /></MuiLink>
                </div>

            </div>
        </div>
    );
};

export default AboutUs;