import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        h1: {
            fontFamily: "'Rubik', cursive",
            fontSize: '3rem',
            marginBottom: '20px',
        },
        body1: {
            fontFamily: "'Blinker', cursive",
            fontSize: '2rem',
            textAlign: 'center',
            marginBottom: '20px',
        },
        body2: {
            fontFamily: "'Blinker'",
            fontSize: '1.5rem',
            // textAlign: 'center',
            marginBottom: '20px',
        },
    },
    spacing: 1, // Adjust the base spacing unit (4px in this case)

});

export default theme;
