import React from 'react';
import styles from '../../assets/styles/shared.module.scss';
import {Typography, Link as MuiLink } from "@mui/material";
import {FaFacebookF, FaInstagram, FaTiktok} from "react-icons/fa";

const Home: React.FC = () => {
    return (
        <div className={styles.centeredLayout}>
            <div className={styles.container}>
                <div className={styles.logoContainer}>
                    <img src="/images/logo/cf_bites.png" alt="Caribbean Fusion Bites Logo" className={styles.logo} />
                </div>

                <Typography variant="h1" component="h1">Coming Soon</Typography>
                <Typography variant="body1">Our food truck is almost ready to serve you delicious meals!</Typography>

                <Typography variant="body1">Follow your taste buds</Typography>

                <div className={styles.socialLinks}>
                    <MuiLink href="https://facebook.com/caribbeanfusionbites" target="_blank"><FaFacebookF /></MuiLink>
                    <MuiLink href="https://instagram.com/cf.bites" target="_blank"><FaInstagram /></MuiLink>
                    <MuiLink href="https://tiktok.com/@cf.bites" target="_blank"><FaTiktok /></MuiLink>
                </div>

            </div>
        </div>

    );
};

export default Home;