import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Typography, Button, Link as MuiLink } from "@mui/material";
import { FaFacebookF, FaInstagram, FaTiktok } from "react-icons/fa";
import styles from '../../assets/styles/shared.module.scss';

const NotFoundPage = () => {
    return (
        <div className={`${styles.centeredLayout} ${styles.notFoundPage}`}>
            <div className={styles.container}>
                <Typography variant="h2" component="h1" gutterBottom>
                    Oops! Page Not Found
                </Typography>
                <Typography variant="body1" paragraph>
                    We can't seem to find the page you're looking for. Let's get you back on track.
                </Typography>

                <Button variant="contained" color="primary" component={RouterLink} to="/" style={{ marginBottom: '20px' }}>
                    Go Home
                </Button>

                <Typography variant="body2" paragraph>
                    Or connect with us on social media:
                </Typography>

                <div className={styles.socialLinks}>
                    <MuiLink href="https://facebook.com/caribbeanfusionbites" target="_blank">
                        <FaFacebookF />
                    </MuiLink>
                    <MuiLink href="https://instagram.com/cf.bites" target="_blank">
                        <FaInstagram />
                    </MuiLink>
                    <MuiLink href="https://tiktok.com/@cf.bites" target="_blank">
                        <FaTiktok />
                    </MuiLink>
                </div>

            </div>
        </div>
    );
};

export default NotFoundPage;
